<template>
  <div>
    <div class="relative z-10 container-bg flex flex-col text-center items-center p-4 md:p-10 2xl:p-20 space-y-8 m-8 lg:m-12 2xl:m-24">
      <div class="w-20 h-20 p-4 rounded-full border-4 border-brand-dark text-brand-dark flex justify-center items-center">
        <div class="w-10 flex justify-center items-center" v-html="icons.check"></div>
      </div>

      <h1 class="2xl:text-5xl">Thank You for confirming your email</h1>

      <p class="text-sm lg:text-medium max-w-3xl text-grey-dark leading-7">
        You will receive an email notification once your account has been approved.
      </p>

      <router-link class="btn-success" :to="{name: 'login'}">Back to login</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmEmail',
  mounted() {
    // NOTE: remove background img (pink substrate)
    document.querySelector('#app').classList.add('thank-you')
  }
};
</script>
